<template>

    <div class="form-compact form-compact-publication">

      <div class="form-compact-header">
        <v-system-bar dark color="primary">
          <v-icon class="mr-1">{{ $t('PUBLICATION.ICON') }}</v-icon>
          <template v-if="id">
            <small><strong class="text-uppercase">{{ $t('PUBLICATION.FORM_TITLE_UPDATE') }}</strong></small>
            <v-icon class="ml-4" size="12">mdi-pound</v-icon>
            <small>{{ loaded_values.id }}</small>
          </template>
          <small v-else><strong class="text-uppercase">{{ $t('PUBLICATION.FORM_TITLE_CREATE') }}</strong></small>
          <v-spacer/>
          <v-icon
            small
            class="clickable"
            @click="$emit('close')"
          >
            mdi-close
          </v-icon>
        </v-system-bar>

        <v-toolbar
          color="transparent"
          flat
          height="66px"
        >

          <v-toolbar-title class="primary--text" v-if="loaded_values && loaded_values.job">
            {{ this.id ? loaded_values.job.title : $t('PUBLICATION.FORM_TITLE_NEW') }}
          </v-toolbar-title>

          <v-spacer />
          <div class="mr-6" >
            <status-label :item="loaded_values"/>
          </div>
          <div class="mr-6 text-nowrap" v-if="ids.length > 1">
            <v-tooltip bottom transition="none">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  dark
                  fab
                  x-small
                  depressed
                  color="grey"
                  @click="prevItem"
                  class="mr-2"
                >
                  <v-icon color="white">mdi-arrow-left</v-icon>
                </v-btn>
              </template>
              {{ $t('COMMON.PREVIOUS') }}
            </v-tooltip>
            <v-tooltip bottom transition="none">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  dark
                  fab
                  x-small
                  depressed
                  color="grey"
                  @click="nextItem"
                >
                  <v-icon color="white">mdi-arrow-right</v-icon>
                </v-btn>
              </template>
              {{ $t('COMMON.NEXT') }}
            </v-tooltip>
          </div>
          <div class="mr-6 d-flex text-nowrap" v-if="id">
            <div>
              <v-tooltip bottom transition="none">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    dark
                    fab
                    x-small
                    depressed
                    color="error"
                    class="mr-2"
                    @click="removeItem"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                {{ $t('COMMON.REMOVE') }}
              </v-tooltip>

              <v-tooltip bottom transition="none">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    dark
                    fab
                    x-small
                    depressed
                    color="indigo"
                    class="mr-2"
                    @click="goToJob"
                  >
                    <v-icon>{{$t('JOB.ICON')}}</v-icon>
                  </v-btn>
                </template>
                {{ $t('PUBLICATION.RELATED_JOB') }}
              </v-tooltip>

              <v-tooltip bottom transition="none">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    dark
                    fab
                    x-small
                    depressed
                    color="warning"
                    class="mr-2"
                    @click="resetItem"
                  >
                    <v-icon>mdi-cancel</v-icon>
                  </v-btn>
                </template>
                {{ $t('COMMON.RESET') }}
              </v-tooltip>
              <v-tooltip bottom transition="none">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    dark
                    fab
                    x-small
                    depressed
                    color="info"
                    @click="refreshItem"
                  >
                    <v-icon>mdi-sync</v-icon>
                  </v-btn>
                </template>
                {{ $t('PUBLICATION.REFRESH') }}
              </v-tooltip>
            </div>
          </div>
          <v-tooltip bottom transition="none">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                fab
                small
                depressed
                color="success"
                @click="saveItem"
                :disabled="!valid"
              >
                <v-icon>mdi-check-bold</v-icon>
              </v-btn>
            </template>
            {{ $t('COMMON.SAVE') }}
          </v-tooltip>
        </v-toolbar>
        <div class="toolbar-separator primary ml-4 mr-4"/>

        <v-dialog v-model="showSaveMultiple" width="350px" overlay-color="transparent">
          <v-card class="pa-4">
            <p class="ma-6 primary--text">
              {{$tc('COMMON.CONFIRM_MULTIPLE_ITEMS', this.ids.length)}}
            </p>
            <div class="d-flex">
              <v-btn rounded depressed large @click="showSaveMultiple = false">
                <v-icon class="mr-2">mdi-close</v-icon>{{$t('COMMON.CANCEL')}}
              </v-btn>
              <v-spacer />
              <v-btn color="success" rounded depressed large @click="saveItem(true)">
                <v-icon class="mr-2">mdi-check</v-icon>{{$t('COMMON.CONFIRM')}}
              </v-btn>
            </div>
          </v-card>
        </v-dialog>

      </div>

      <div :class="['form-compact-content', blocked ? 'blocked' : '']">
        <v-overlay
          :value="blocked"
          v-if="loaded_values"
          color="white"
          :opacity="0.75"
          absolute
        >
          <div class="text-center">
            <v-icon :color="loaded_values.status_type" size="42">
              {{ $t(loaded_values.status_code + '_ICON') }}
            </v-icon>
            <p class="black--text">{{ $t(loaded_values.status_code) }}</p>
            <div class="mt-4">
              <v-btn
                depressed
                small
                color="grey"
                @click="loadItem"
              >
                {{ $t('COMMON.RELOAD') }}
              </v-btn>
            </div>
          </div>
        </v-overlay>
        <Loading :value="!!loading" />
        <v-container fluid class="pt-4 pb-10">

          <div class="pa-4 pb-0" v-if="typeof jobStatusReport === 'object'">
            <v-alert
              outlined
              type="error"
              icon="mdi-alert"
              dense
            >
              <p class="mb-0">L'offre ne sera pas acceptée sur ce canal car il ne dispose pas de toutes les données requises.</p>
              <a @click="showReport = true" v-if="!showReport">En savoir plus</a>
              <transition>
                <div v-if="showReport" class="mt-2">
                  <template>
                    <v-row>
                      <v-col cols="9">
                        <div :key="'err'+j" v-for="(error, j) in jobStatusReport">
                          <v-chip x-small outlined color="error" class="mr-4">{{$t(error.code)}}</v-chip><small>{{error.field}}</small>
                        </div>
                      </v-col>
                    </v-row>
                  </template>
                </div>
              </transition>
            </v-alert>
          </div>

          <div class="pa-4 pb-0" v-if="loaded_values && loaded_values.status_message">
            <v-alert
              outlined
              type="error"
              icon="mdi-alert"
              dense
            >
              <p class="mb-0">{{loaded_values.status_message}}</p>
            </v-alert>
          </div>

          <v-row class="ma-0" v-if="loaded_values">
            <v-col cols="3" class="text-center pt-6">
              <v-img :src="getImage(loaded_values.channel)" width="120px" max-width="100%" contain />
              <p class="primary--text mt-1">{{loaded_values.channel.title}}</p>
            </v-col>
            <v-col cols="8">

              <v-row class="mt-0 mb-0 text-medium">
                <v-col cols="4" class="text-ellipsis text-nowrap grey--text">
                  {{$t('PUBLICATION.FIELD_METHOD')}}
                </v-col>
                <v-col cols="8" class="text-ellipsis text-nowrap">
                  <v-chip class="mr-2" small :key="'me'+i" v-for="(method, i) in loaded_values.channel_subscription.methods">
                    {{method}}
                  </v-chip>
                </v-col>
              </v-row>


              <v-divider />
              <v-row class="mt-0 mb-0 text-medium">
                <v-col cols="4" class="text-ellipsis text-nowrap grey--text">
                  {{$t('PUBLICATION.FIELD_MODE')}}
                </v-col>
                <v-col cols="8" class="text-ellipsis text-nowrap">
                  <v-chip class="mr-2" :color="loaded_values.channel_subscription.channel_params.test_mode ? 'warning' : ''" small>
                    {{loaded_values.channel_subscription.channel_params.test_mode ? $t('COMMON.TRUE') : $t('COMMON.FALSE') }}
                  </v-chip>
                </v-col>
              </v-row>

            </v-col>
          </v-row>


          <v-form v-model="valid">
            <div class="pr-6 pl-6">
              <FormPublicationCompactPrice @input="handleInput" :values="values" :loaded_values="loaded_values" />
            </div>
          </v-form>

          <template v-if="loaded_values && loaded_values.creative">
            <div class="pr-6 pl-6">
              <p>{{$t('CREATIVE.TITLE')}} <small class="grey--text">{{loaded_values.creative.content.width + 'x' + loaded_values.creative.content.height}}</small></p>
              <BannerEditorPreview :values="loaded_values.creative" :data="loaded_values" />

            </div>
          </template>

        </v-container>
      </div>

    </div>

</template>

<script>

import Loading from '@/components/Loading'
import StatusLabel from '@/components/StatusLabel'
import FormPublicationMixins from '../FormPublicationMixins'
import FormPublicationCompactPrice from './FormPublicationCompact.price'
import BannerEditorPreview from '../../creative/BannerEditor.preview'

export default {
  name:"FormPublicationCompact",
  mixins: [FormPublicationMixins],
  components: {
    Loading,
    StatusLabel,
    FormPublicationCompactPrice,
    BannerEditorPreview,
  },
  data: () => ({
    showReport: false,
    valid: false,
    interval: null,
  }),
  computed: {
    blocked () {
      return this.loaded_values ? ['info', 'warning'].indexOf(this.loaded_values.status_type) !== -1 : false
    }
  },
  watch: {
    blocked (n) {
      if (!n) this.stopInterval()
      else this.startInterval()
    }
  },
  beforeDestroy () {
    this.stopInterval()
  },
  methods: {

    goToJob () {
      const route = this.$router.currentRoute.fullPath
      const split = route.split('/')
      const product = split[1]
      this.$router.push('/' + product + '/jobs/?edit=' + this.loaded_values.job_id)
    },

    getImage (item, size = "original") {
      const url = `${process.env.VUE_APP_ASSETS}/channels/`
      return `${url + item.controller_name.toLowerCase()  }.png`
    },

    startInterval () {
      clearInterval(this.interval)
      this.interval = setInterval(() => {
        this.loadItem()
      }, 5000)
    },

    stopInterval () {
      clearInterval(this.interval)
      this.$emit('update')
    },

  }
}
</script>
